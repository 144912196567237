<template>
  <div id="Manage"
       v-title
       data-title="酒店民宿">
    <!-- 产品管理   酒店民宿 -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel="TabsFormLabel"
                     :activeTabName.sync="activeTabName"
                     ref="elTable"
                     @clickInputBtn="clickAddIngLat"
                     @clickToAddEquip="clickToAddEquip"
                     @tabHandleClick="swichTab"
                     @handleRemove="handleRemove"
                     @handlePreview="handlePreview"
                     @clickDeleteData="clickDeleteEquip"></CommonTabForm>
      <el-dialog :title="inDialog.title"
                 :visible.sync="inDialog.isShowDialogVisible"
                 append-to-body>
        <p class=""> 点击“搜索”进行选择 , 然后点击“确认”获取 经纬度 </p>
        <CommonAmap :selectArea.sync="openForm.areaLabel"
                    @submitAddress="submitAddress"></CommonAmap>
      </el-dialog>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(2, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(3, auditForm)">通 过</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 放大图片-->
    <el-dialog :visible.sync="dialogImgVisible"
               append-to-body>
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加酒店/民宿</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @changeSwitch="changeSwitch"
                       @clickEditData="clickEditData"
                       @clickWarningData="clickAuditData"
                       @clickInfoData="clickInfoData"
                       @clickDeleteData="clickDeleteData"
                       @tabHandleClick="swichTable"
                       @getPage="getPage"
                       @getNumArticle="getNumArticle"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>

import {
  getHotelProductToAudit, getHotelProductEdit, getHotelProductShow, deleteHotelProduct, getHotelProductToOperation,
  AuditHotelProduct, setHotelProductdescription, setHotelProductbuyNotice, changeHotelProductStatue, setHotelProductoPlicyInfo, createBindQrcode
} from 'api/productManage.js'

import CommonForm from 'components/CommonForm.vue'
// import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonAmap from 'components/CommonAmap.vue'

export default {
  components: {
    CommonForm,
    // CommonTable,
    CommonManageMain,
    CommonTabForm,
    CommonAmap
  },
  data () {
    return {
      // 主页面组件布局
      current_status: null,// 当前酒店状态
      imgIndex: [], // 获取删除后的图片
      searchData: '', // 存放搜索的数据，分页时使用
      ticket: null,//酒店二维码
      tableFormLabel: [
        {
          name: 'first',
          label: '待审核',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '序号',
              type: 'id',
              width: '100px'
            },
            {
              prop: 'name',
              label: '标题',
              width: '200px'
            },
            {
              prop: 'hotel_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'abbreviation',
              label: '供应商简称'
            },
            {
              prop: 'city_name',
              label: '所属城市'
            },
            {
              prop: 'starting_price',
              label: '销售价'
            },
            {
              prop: 'last_date',
              label: '可订日期',
              type: 'dateTime'
            },
            {
              prop: 'update_time',
              label: '更新时间',
              width: '200px'
            },
            {
              prop: 'admin_id',
              label: '操作员'
            },
            {
              prop: 'status',
              label: '状态',
              type: 'State'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '编辑房型',
                  type: 'info'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
        {
          name: 'second',
          label: '仓库中',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '序号',
              type: 'id'
            },
            {
              prop: 'name',
              label: '标题'
            },
            {
              prop: 'hotel_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'abbreviation',
              label: '供应商简称'
            },
            {
              prop: 'city_name',
              label: '所属城市'
            },
            {
              prop: 'starting_price',
              label: '销售价'
            },
            {
              prop: 'last_date',
              label: '可订日期',
              type: 'dateTime'
            },
            {
              prop: 'update_time',
              label: '更新时间',
              width: '200px'
            },
            {
              prop: 'admin_id',
              label: '操作员'
            },
            {
              prop: 'status',
              label: '下/上架',
              type: 'state'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              width: '180px',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '编辑房型',
                  type: 'info'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
        {
          name: 'third',
          label: '销售中',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '序号',
              type: 'id'
            },
            {
              prop: 'name',
              label: '标题'
            },
            {
              prop: 'hotel_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'abbreviation',
              label: '供应商简称'
            },
            {
              prop: 'city_name',
              label: '所属城市'
            },
            {
              prop: 'starting_price',
              label: '销售价'
            },
            {
              prop: 'last_date',
              label: '可订日期',
              type: 'dateTime'
            },
            {
              prop: 'update_time',
              label: '更新时间',
              width: '200px'
            },
            {
              prop: 'admin_id',
              label: '操作员'
            },
            {
              prop: 'status',
              label: '下/上架',
              type: 'state'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              width: '180px',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '编辑房型',
                  type: 'info'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
        {
          name: 'fouth',
          label: '异常酒店',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '序号',
              type: 'id',
              width: '100px'
            },
            {
              prop: 'name',
              label: '标题',
              width: '200px'
            },
            {
              prop: 'hotel_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'abbreviation',
              label: '供应商简称'
            },
            {
              prop: 'city_name',
              label: '所属城市'
            },
            {
              prop: 'starting_price',
              label: '销售价'
            },
            {
              prop: 'last_date',
              label: '可订日期',
              type: 'dateTime'
            },
            {
              prop: 'update_time',
              label: '更新时间',
              width: '200px'
            },
            {
              prop: 'admin_id',
              label: '操作员'
            },
            {
              prop: 'status',
              label: '状态',
              type: 'State'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '编辑房型',
                  type: 'info'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
        {
          name: 'five',
          label: '修改后',
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '序号',
              type: 'id'
            },
            {
              prop: 'name',
              label: '标题'
            },
            {
              prop: 'hotel_pic',
              label: '封面图',
              type: 'image'
            },
            {
              prop: 'abbreviation',
              label: '供应商简称'
            },
            {
              prop: 'city_name',
              label: '所属城市'
            },
            {
              prop: 'starting_price',
              label: '销售价'
            },
            {
              prop: 'last_date',
              label: '可订日期',
              type: 'dateTime'
            },
            {
              prop: 'update_time',
              label: '更新时间',
              width: '200px'
            },
            {
              prop: 'admin_id',
              label: '操作员'
            },
            {
              prop: 'status',
              label: '下/上架',
              type: 'state'
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              width: '180px',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '编辑房型',
                  type: 'info'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            // 显示表格top按钮
            isShowTopBtn: true
          }
        },
      ],

      // 对话框 表单组件
      TabsFormLabel: [
        {
          name: 'first',
          label: '基本信息',
          type: 'form',
          children: [ // form表单 组件
            {
              model: 'name',
              label: '民宿/酒店名称',
              width: '400px',
              required: true
            },
            {
              model: 'describe',
              label: '民宿/酒店简称',
              width: '600px',
              type: 'textarea',
              required: true
            },
            {
              model: 'supplier_id',
              label: '所属供应商',
              type: 'select',
              required: true,
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '观视界自营' }
              ]
            },
            {
              model: 'level',
              label: '所属星级',
              type: 'select',
              options: [
                // value:'值',label:'标签'
                { value: 1, label: '二星' },
                { value: 2, label: '三星' },
                { value: 3, label: '四星' },
                { value: 4, label: '五星' },
                { value: 5, label: '六星' },
                { value: 6, label: '七星' }
              ],
              required: true
            },
            {
              model: 'vr_model',
              label: 'VR模型',
              width: '400px',
              required: true
            },
            {
              model: 'hotel_tags',
              label: '酒店标签',
              type: 'tab_create',
              required: true
            },
            {
              model: 'hotel_pic',
              label: '封面图',
              type: 'upload',
              maxSize: 2048,
              // imgHight =长
              // imgWidth =高
              imgHeight: '1280',
              imgWidth: '800',
              clues: '建议尺寸：1280*800像素，且不超过2MB',
              required: true
            },
            {
              model: 'images',
              label: '轮播图',
              type: 'uploadList',
              maxSize: 2048,
              imgHeight: '1280',
              imgWidth: '800',
              clues: '建议尺寸：1280*800像素，且不超过2MB',
              required: true
            },
            {
              model: 'landlord_name',
              label: '房东姓名',
              width: '400px',
              required: true
            },
            {
              model: 'landlord_tel',
              label: '房东联系方式',
              width: '450px'
            },
            {
              model: 'landlord_mobile',
              label: '短信通知号码',
              width: '400px',
              type: 'inputClues',
              clues: '提示:用于酒店接受新订单短信提醒'
            },
            {
              model: 'license_img',
              label: '营业执照',
              type: 'upload',
              imgHeight: '1280',
              imgWidth: '808',
              maxSize: 2048,
              clues: '建议尺寸：不超过200KB'
            },
            {
              model: 'landlord_wx',
              label: '微信图',
              type: 'upload',
              imgHeight: '1280',
              imgWidth: '808',
              maxSize: 2048,
              clues: '建议尺寸：不超过200KB'
            },
            {
              model: 'area',
              area: {
                area1: 'province_id',
                area2: 'city_id',
                area3: 'county_id',
                area4: 'town_id'
              },
              label: '酒店/民宿地址',
              type: 'cascaderArea',
              width: '100%',
              required: true
            },
            {
              model: 'address',
              label: '详细地址',
              labelOFF: 'off',
              width: '500px',
              required: true
            },
            {
              model: 'lngAndlat',
              label: '经纬度',
              type: 'inputButton',
              size: 'mini',
              width: '450px',
              name: '获取经纬度'
            },
            {
              model: 'is_red',
              label: '推荐',
              type: 'switch'
            },
            {
              model: 'is_hot',
              label: '热门',
              type: 'switch'

            },
            {
              model: 'mode',
              label: '住房类型',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '不限' },
                { value: 1, label: '民宿' },
                { value: 2, label: '酒店' }
              ],
              required: true
            },
            {
              model: 'buy_type',
              label: '订购类型',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '不限' },
                { value: 1, label: '预订' },
                { value: 2, label: '订购' }
              ],
              required: true
            },
            {
              label: '微信扫码添加提醒人员',
              type: 'text2'
            },
            {
              type: 'QRcode',
              images: ''
            },
            {
              label: '已添加消息提醒人员',
              type: 'text2'
            },
            {
              type: 'unbind',
              value: [
                {
                  id: 1,
                  nickname: '观视界',
                  unbind: '解绑'
                },
                {
                  id: 2,
                  nickname: '观视界2',
                  unbind: '解绑'
                }
              ]


            }
            // {
            //   model: 'book_ahead_minutes',
            //   label: '提前多少分钟预定',
            //   width: '400px'
            // },
            // {
            //   model: 'min_buy_num',
            //   label: '产品最低购买份数',
            //   width: '330px',
            //   type: 'inputClues',
            //   clues: '0为不限制 1为最少购买一份'
            // },
            // {
            //   model: 'max_buy_num',
            //   label: '产品最多购买份数',
            //   width: '330px',
            //   type: 'inputClues',
            //   clues: '0为不限制 1为最少购买一份'
            // },
            // {
            //   model: 'min_buy_night',
            //   label: '产品最低购买晚数',
            //   width: '330px',
            //   type: 'inputClues',
            //   clues: '0为不限制 1为最少购买一份'
            // },
            // {
            //   model: 'max_buy_night',
            //   label: '产品最多购买晚数',
            //   width: '330px',
            //   type: 'inputClues',
            //   clues: '0为不限制 1为最少购买一份'
            // },
            // {
            //   model: 'is_presale',
            //   label: '是否支持全网预售',
            //   type: 'radio',
            //   options: [
            //     // value:'值',label:'标签'
            //     { value: 0, label: '否' },
            //     { value: 1, label: '是' }
            //   ]
            // },
            // {
            //   model: 'presale_display_begin',
            //   label: '展示开始时间',
            //   type: 'datetime'
            // },
            // {
            //   model: 'presale_display_end',
            //   label: '展示结束时间',
            //   type: 'datetime'
            // },
            // {
            //   model: 'presale_begin',
            //   label: '预售开始时间',
            //   type: 'datetime'
            // },
            // {
            //   model: 'presale_end',
            //   label: '预售结束时间',
            //   type: 'datetime'
            // },
            // {
            //   model: 'presale_use_begin',
            //   label: '预售使用开始时间',
            //   width: '330px'
            // },
            // {
            //   model: 'presale_use_end',
            //   label: '预售使用结束时间',
            //   width: '330px'
            // },
            // {
            //   model: 'presale_description',
            //   label: '预售说明',
            //   width: '595px',
            //   type: 'textarea'
            // },
            // {
            //   label: '预定规则',
            //   type: 'text2'
            // },
            // {
            //   model: 'type',
            //   label: '规则类型',
            //   type: 'select',
            //   options: [
            //     { value: 0, label: '联系人' },
            //     { value: 1, label: '出行人' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'cn_name',
            //   label: '是否启用中文姓名',
            //   type: 'select',
            //   options: [
            //     { value: false, label: '否' },
            //     { value: true, label: '是' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'en_name',
            //   label: '是否启用英文姓名',
            //   type: 'select',
            //   options: [
            //     { value: false, label: '否' },
            //     { value: true, label: '是' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'mobile',
            //   label: '是否启用电话',
            //   type: 'select',
            //   options: [
            //     { value: false, label: '否' },
            //     { value: true, label: '是' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'email',
            //   label: '是否启用邮箱',
            //   type: 'select',
            //   options: [
            //     { value: false, label: '否' },
            //     { value: true, label: '是' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'people_num',
            //   label: '需要填写人数',
            //   clues: '-3:三份套餐填一个;- 2:两份套餐填一个;-1:一张订单填一个;0:不需要;1：一份套餐填一个;2：一份套餐填两个以此类推',
            //   required: true,
            //   width: '475px',
            //   type: 'inputClues'
            // },
            // {
            //   model: 'credential',
            //   label: '是否启用证件',
            //   type: 'select',
            //   options: [
            //     { value: false, label: '否' },
            //     { value: true, label: '是' }
            //   ],
            //   required: true
            // },
            // {
            //   model: 'credential_type',
            //   label: '证件支持类型',
            //   type: 'select',
            //   options: [
            //     { value: 0, label: '身份证' },
            //     { value: 1, label: '护照' },
            //     { value: 2, label: '港澳通行证' },
            //     { value: 3, label: '台湾通行证' },
            //     { value: 4, label: '回乡证' },
            //     { value: 5, label: '台胞证' },
            //     { value: 6, label: '士兵证' },
            //     { value: 7, label: '军官证' },
            //     { value: 99, label: '其他' }
            //   ],
            //   required: true
            // },
            // {
            //   label: '限购规则',
            //   type: 'text2'
            // },
            // {
            //   model: 'buy_limit_type',
            //   label: '限购类型',
            //   type: 'select',
            //   options: [
            //     { value: 1, label: '身份证' },
            //     { value: 2, label: '手机' }
            //   ]
            // },
            // {
            //   model: 'in_day',
            //   label: '多少天内',
            //   width: '300px'
            // },
            // {
            //   model: 'in_count',
            //   label: '购买多少份',
            //   width: '300px'
            // }
          ]
        },
        {
          name: 'second',
          label: '酒店(民宿)简介',
          type: 'wangedito',
          model: 'description',
          wangeDitorConfig: {
            height: 808,
            focus: true,
            placeholder: '请输入酒店(民宿)简介...'
          }
        },
        {
          name: 'third',
          label: '酒店(民宿)政策',
          type: 'form',
          children: [
            {
              model: 'entry_departure_time',
              label: '入离时间'
            },
            {
              model: 'deposit',
              label: '押金政策'
            },
            {
              model: 'unsubscribe',
              label: '退订政策'
            },
            {
              model: 'pets',
              label: '宠物政策'
            },
            {
              model: 'add_beds',
              label: '加床政策'
            },
            {
              model: 'meal_explanation',
              label: '用餐说明'
            },
            {
              model: 'facility',
              label: '服务/设施',
              type: 'tabSelect',
              clues: '提示: 输入添加 服务/设施 类别，例：公共设施、停车场等'
            }
          ]
        },
        {
          name: 'fourth',
          label: '订购须知',
          type: 'wangedito',
          model: 'buy_notice',
          wangeDitorConfig: {
            height: 808,
            focus: true,
            placeholder: '请输入订购须知...'
          }
        }

      ],

      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增酒店/民宿',
        editName: '编辑酒店/民宿',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核酒店民宿
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      // form表单 审核
      auditForm: {
        id: '',
        name: '',
        image: '',
        status: '',
        reason: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '民宿/酒店编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'name',
          label: '民宿/酒店名称',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'image',
          label: '封面图',
          type: 'image'
        },
        {
          model: 'reason',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        }

      ],

      // form表单 基础数据
      openForm: {
        // 基础数据
        id: '',
        name: '',
        describe: '',
        supplier_id: '',
        level: '',
        buy_type: 0,
        mode: 0,
        vr_model: '',
        hotel_pic: '',
        images: [],
        hotel_tags: ['24小时', '夏季特惠'],
        province_id: '',
        city_id: '',
        county_id: '',
        town_id: 0,
        area: '',
        address: '',
        lng_lat: [],
        lngAndlat: '', // lng_lat转换用的,方便展示
        license_img: '',
        landlord_wx: '',
        landlord_tel: '',
        landlord_name: '',
        landlord_mobile: '',
        status: 0,
        is_hot: 0,
        is_red: 0,
        landlord_mobile: '', // 短信通知号码
        // 预订
        book_ahead_minutes: '',
        min_buy_num: '',
        max_buy_num: '',
        min_buy_night: '',
        max_buy_night: '',
        is_presale: '',
        presale_display_begin: '',
        presale_display_end: '',
        presale_begin: '',
        presale_end: '',
        presale_use_begin: '',
        presale_use_end: '',
        presale_description: '',
        // 预订规则列表
        book_limit: [],		// array	预订规则列表
        type: '',
        cn_name: '',
        en_name: '',
        mobile: '',
        email: '',
        people_num: '',
        credential: '',
        credential_type: '',
        //	限购规则
        buy_limit: [],	// array	限购规则
        buy_limit_type: '',
        in_day: '',
        in_count: '',

        // 酒店民宿介绍  富文本数据
        description: ' ',
        scenic_spots: [1, 2],
        // 订购须知
        buy_notice: ' ',
        // 酒店政策
        entry_departure_time: ' ',
        deposit: ' ',
        unsubscribe: ' ',
        pets: ' ',
        add_beds: ' ',
        meal_explanation: ' ',
        facility: [{
          name: '停车场',
          icon: 'stop',
          data: ['免费停车场', '无充电车位']
        },
        {
          name: '网络',
          icon: 'wifi',
          data: ['公用区WiFi免费']
        },
        {
          name: '前台服务',
          icon: 'front',
          data: ['行李寄存', '叫醒服务', '24小时前台', '叫车服务', '前台贵重物品保险柜', '旅游交通图']
        },
        {
          name: '餐饮服务',
          icon: 'food',
          data: ['中餐厅', '西餐厅', '咖啡厅', '送餐服务']
        },
        {
          name: '通用设施',
          icon: 'general',
          data: ['无烟楼层', '吸烟区', '中央空调', '电梯', '暖气', '安全报警器']
        },
        {
          name: '其他服务',
          icon: 'other',
          data: ['外送洗衣服务', '管家服务', '洗涤用具', '保安人员', '干洗', '多语种服务']
        }
        ]
      },
      // form表单 搜索数据
      searchForm: {
        hotelName: '',
        supplier_id: '',
        hotelStatus: '',
        mode: '',
        buy_type: '',
        province_id: '',
        city_id: '',
        last_date: [],
        order_by: ''
      },
      searchformLabel: [
        {
          model: 'hotelName',
          label: '酒店名称',
          width: '304px'
        },
        {
          model: 'supplier_id',
          label: '供应商名称',
          width: '304px',
          type: 'select',
          options: [
            {
              value: 0,
              label: '观视界自营'
            }
          ]
        },
        {
          model: 'province_id',
          area1: 'province_id',
          label: '地址',
          type: 'provinceArea',
          width: '250px',
          placeholder: '省级'
        },
        {
          model: 'city_id',
          area2: 'city_id',
          type: 'cityArea',
          width: '90px',
          placeholder: '市级'
        },
        {
          model: 'hotelStatus',
          label: '酒店状态',
          type: 'select',
          width: '250px',
          placeholder: '请选择',
          options: [
            { value: 0, label: '申请' },
            { value: 1, label: '正常' },
            { value: 2, label: '驳回' },
            { value: 3, label: '下架' }
          ]
        },
        {
          model: 'mode',
          label: '住房类型',
          type: 'select',
          width: '250px',
          options: [
            { value: 0, label: '不限' },
            { value: 1, label: '民宿' },
            { value: 2, label: '酒店' }
          ]
        },
        {
          model: 'buy_type',
          label: '类型',
          type: 'select',
          width: '250px',
          options: [
            { value: 0, label: '不限' },
            { value: 1, label: '预定' },
            { value: 2, label: '订购' }
          ]
        },
        {
          model: 'last_date',
          label: '可订日期范围',
          width2: '200px',
          type: 'datePicker'
        },
        {
          model: 'order_by',
          label: '列表排序',
          type: 'selectClues',
          width: '230px',
          options: [
            { value: 'asc', label: '正序' },
            { value: 'desc', label: '倒序' }
          ],
          clues: '(排序可订日期)'
        }
      ],
      // 内层Dialog
      inDialog: {
        title: '选择经纬度',
        isShowDialogVisible: false
      },

      // table 表格 基础数据
      tableData: [],
      // 酒店、民宿-全部-数据
      hotelAllDataObj: {},
      // catgory_id最大值
      MaxId: '',
      // 待审核 或 正式运营 Name
      activeTableName: 'first',
      // Dialog新增/编辑 Name
      activeTabName: 'first',
      // Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      // 提交保存信息的 按钮名称
      dialogBtnName: '下一步'
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'HotelHomeStay_HomeType') {
      to.meta.isBack = true
      // 判断是从哪个路由过来的，
      // 如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    }
    // 继续执行
    next()
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      this.updataTableData()
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false
  },
  created () {
    this.isFirstEnter = true
    // 只有第一次进入或者刷新页面后才会执行此钩子函数，使用keep-alive后（2+次）进入不会再执行此钩子函数
  },
  mounted () {
    // 初始table数据
    this.getHotelProductToAudit()
    this.keyupEnter()
  },

  watch: {
    // 将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
          status: 'status'
        },
        0
      )
    },
    activeTabName: function () {
      if (this.activeTabName == 'fourth') {
        this.dialogBtnName = '保 存'
      } else {
        this.dialogBtnName = '下一步'
      }
    }
  },
  methods: {
    // 键盘Enter事件
    keyupEnter () {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          this.keyEnter()
        }
      }
    },
    // 键盘Enter搜索事件
    keyEnter (searchForm) {
      console.log(this.searchForm)
      this.searchHotelProductToAudit(this.searchForm)
    },
    // 待审核 酒店、民宿 请求接口
    async getHotelProductToAudit (data) {
      console.log("======请求待审核 酒店数据=======", data);
      if (data) {
        this.current_status = data.status
        await getHotelProductToAudit(data).then(res => {
          console.log("待审核中1");
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[0].tableConfig.total = DATA.data.total
            this.tableFormLabel[0].tableConfig.loading = false
            getHotelProductToAudit(data)
            console.log(this.hotelAllDataObj)
          }
        })
      } else if (this.current_status == 2) {
        console.log("待审核中2");

        await getHotelProductToAudit({
          status: this.current_status,
          page: this.tableFormLabel[3].tableConfig.page,
          page_rows: this.tableFormLabel[3].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[3].tableConfig.total = DATA.data.total
            this.tableFormLabel[3].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      }
      else {
        console.log("待审核中3");

        await getHotelProductToAudit({
          status: this.current_status,
          page: this.tableFormLabel[0].tableConfig.page,
          page_rows: this.tableFormLabel[0].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[0].tableConfig.total = DATA.data.total
            this.tableFormLabel[0].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      }
    },

    // 正式运营  请求接口
    async getHotelProductToOperation (data) {
      console.log(data, "111=========data");
      if (data) {
        this.current_status = data.status
        // 销售中:1状态
        console.log("正式运营1");
        await getHotelProductToOperation(data).then(res => {
          console.log("执行了销售中");
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[2].tableConfig.total = DATA.data.total
            this.tableFormLabel[2].tableConfig.loading = false
            this.tableFormLabel[1].tableConfig.loading = false
            this.tableFormLabel[1].tableConfig.total = DATA.data.total
            this.tableFormLabel[4].tableConfig.loading = false
            this.tableFormLabel[4].tableConfig.total = DATA.data.total
            console.log(this.tableFormLabel[2].tableConfig.loading);
            getHotelProductToOperation(data)
            console.log(DATA)
          }
        })
        this.tableFormLabel[2].tableConfig.page = 1
        this.tableFormLabel[1].tableConfig.page = 1
      } else if (data) {
        this.current_status = data.status
        // 仓库中:状态3
        console.log("正式运营3");
        await getHotelProductToOperation(data).then(res => {
          console.log("执行了销售中");
          const DATA = res.data
          if (DATA.code == 200 && this.current_status3) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[1].tableConfig.total = DATA.data.total
            this.tableFormLabel[1].tableConfig.loading = false
            console.log(this.tableFormLabel[1].tableConfig.loading);
            getHotelProductToOperation(data)
            console.log(DATA)
          }
        })
      } else if (this.current_status == 1) {
        console.log("正式运营4");
        await getHotelProductToOperation({
          status: this.current_status,
          page: this.tableFormLabel[2].tableConfig.page,
          page_rows: this.tableFormLabel[2].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[2].tableConfig.total = DATA.data.total
            this.tableFormLabel[2].tableConfig.loading = false
            // console.log(DATA)
          }
        })
      } else {
        console.log("正式运营2");
        await getHotelProductToOperation({
          page: this.tableFormLabel[1].tableConfig.page,
          page_rows: this.tableFormLabel[1].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[1].tableConfig.total = DATA.data.total
            this.tableFormLabel[1].tableConfig.loading = false
            // console.log(DATA)
          }
        })
      }
    },
    // 获得下列分类页的页数
    getPage () {
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[0].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
          this.getHotelProductToAudit(this.searchData)
        } else {
          this.getHotelProductToAudit()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[1].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
          this.getHotelProductToOperation(this.searchData)
        }
        else {
          this.getHotelProductToOperation()
        }
      }
      else if (this.activeTableName == 'third') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[2].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[2].tableConfig.NumArticle
          this.getHotelProductToOperation(this.searchData)
        }

        else {
          this.getHotelProductToOperation()
        }
      }
      else if (this.activeTableName == 'fouth') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[3].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[3].tableConfig.NumArticle
          this.getHotelProductToAudit(this.searchData)
        }
        else {
          this.getHotelProductToAudit()
        }
      }
    },

    // 获取下面分页的页数的最大条数
    getNumArticle (NumArticle) {
      this.tableFormLabel[0].tableConfig.NumArticle = NumArticle
      this.tableFormLabel[1].tableConfig.NumArticle = NumArticle
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[0].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
          this.getHotelProductToAudit(this.searchData)
        } else {
          this.getHotelProductToAudit()
        }
      } else if (this.activeTableName == '  ') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[1].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
          this.getHotelProductToOperation(this.searchData)
        } else {
          this.getHotelProductToOperation()
        }
      }
    },
    // 新增、 编辑酒店、民宿 请求接口
    getHotelProductEdit (met, data) {
      return new Promise(resolve => {
        getHotelProductEdit(met, data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })
    },

    // 审核酒店、民宿  接口
    AuditHotelProduct (data) {
      return new Promise(resolve => {
        AuditHotelProduct(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    // 保存-民宿/酒店介绍 接口
    setHotelProductdescription (data) {
      return new Promise(resolve => {
        setHotelProductdescription(data).then((res) => {
          const DATA = res.data
          resolve(DATA)
        })
      })
    },

    // 保存-订购须知 接口
    setHotelProductbuyNotice (data) {
      return new Promise(resolve => {
        setHotelProductbuyNotice(data).then((res) => {
          const DATA = res.data
          resolve(DATA)
        })
      })
    },

    // 保存-酒店政策
    async setHotelProductoPlicyInfo (data) {
      return await new Promise(resolve => {
        setHotelProductoPlicyInfo(data).then((res) => {
          const DATA = res.data
          resolve(DATA)
        })
      })
    },
    // 酒店/民宿-变更上/下架状态 接口
    async changeHotelProductStatue (data) {
      await changeHotelProductStatue(data).then((res) => {
        const DATA = res.data
        console.log(res)
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: DATA.message,
            type: 'warning'
          })
        }
      })
      console.log(data, "按钮data");
      if (data.status == 3) {
        let status = {
          status: 1
        }
        this.getHotelProductToOperation(status)
      } else if (data.status == 1) {
        let status = {
          status: 3
        }
        this.getHotelProductToOperation(status)
      }

    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 处理标准时间
    dateToMs (date) {
      const result = new Date(date).getTime()
      return result
    },
    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 切换tab 待审核、正式运营
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name

      // 刷新
      this.updataTableData()
    },
    // 切换tab Dialog新增、编辑
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
    },
    // 刷新表格数据
    updataTableData () {
      console.log(this.activeTableName)
      // 待审核
      if (this.activeTableName == 'first') {
        let data = {
          status: 0,
          order_by: ['update_time desc']
        }
        this.getHotelProductToAudit(data)
      } else if (this.activeTableName == 'second') {
        //仓库=下架
        let data = {
          status: 3,
          is_need_review: 0,
          order_by: ['update_time desc']

        }
        this.getHotelProductToOperation(data)
        // this.getHotelProductToOperation(data)
      }
      else if (this.activeTableName == 'third') {
        //上架中
        let data = {
          status: 1,
          order_by: ['update_time desc']


        }
        this.getHotelProductToOperation(data)
      } else if (this.activeTableName == 'fouth') {
        // 酒店异常
        let data = {
          status: 2,
          order_by: ['update_time desc']

        }
        this.getHotelProductToAudit(data)
        // this.tableFormLabel[3].tableConfig.total = DATA.data.total
        this.tableFormLabel[3].tableConfig.loading = false
        // 修改后
      } else if (this.activeTableName == 'five') {
        let data = {
          status: 3,
          is_need_review: 1,
          order_by: ['update_time desc']

        }
        this.getHotelProductToOperation(data)
      }
      else {
        console.log('出错了！')
      }
    },

    // 添加数据操作
    clickAddData () {
      this.openForm = this.$options.data().openForm
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
      // console.log(this.$refs.elTable.page)
    },

    // 确定提交数据操作
    async clickSubmitData (data) {
      const name = this.activeTabName// 当前Dialog Tab页面
      // 根据点击的页面，进行保存
      // 1.基本信息的保存
      // 2.酒店政策保存
      // 3.民宿/酒店介绍保存
      // 4.订购须知保存
      console.log(data)
      let res// 返回处理结果
      if (name == 'first') { // 基本信息
        res = await this.saveMainnformation(data)
        this.updataTableData()
        data.id = res.data.id == undefined ? data.id : res.data.id
        this.activeTabName = res.code == 200 ? 'second' : this.activeTabName
      } else if (name == 'second') { /// 民宿/酒店介绍
        res = await this.savaDescriptionInfo(data)
        this.activeTabName = res.code == 200 ? 'third' : this.activeTabName
      } else if (name == 'third') { // 酒店政策
        res = await this.savaPolicyInfo(data)
        this.activeTabName = res.code == 200 ? 'fourth' : this.activeTabName
      } else if (name == 'fourth') { // 订购须知
        res = await this.saveBuyNoticeInfo(data)
        // 关闭 dialog
        await this.updataTableData()
        this.dialogFonfig.isShowDialogVisible = false
        this.openForm = this.$options.data().openForm
        this.activeTabName = 'first'
      } else {
      }
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        })
      } else {
        this.$message({
          message: res.message,
          type: 'error'
        })
      }
    },
    // 保存-基本信息
    async saveMainnformation (data) {
      // {}
      // ----------------数据格式-----------------
      // name		string	民宿/酒店名称
      // describe		string	民宿/酒店简述
      // landlord_mobile   短信通知电话
      // supplier_id		int	所属供应商 0.平台
      // level		int	所属等级
      // mode		string	模式 0不限,1民宿,2酒店
      // buy_type		int	类型 0不限,1预订,2订购
      // vr_model		string	VR模型
      // hotel_pic		string	封面图
      // images		arr	轮播图
      // hotel_tags		arr	酒店标签
      // province_id		int	地址一级 ID
      // city_id		int	地址二 级 ID
      // county_id		int	地址三 级 ID
      // town_id		int	地址四级 ID
      // address		string	详细地址
      // lng_lat		arr	经纬度
      // license_img		string	头像
      // landlord_wx		string	微信图
      // landlord_name		string	联系人
      // landlord_tel		string	联系方式
      // hotel_pic		string	封面图
      // is_hot		int	是否热门 0否 1是
      // is_red		int	是否推荐 0否 1是
      const type = this.dialogFonfig.openType
      let result// 主信息上传是否成功
      // 处理标准时间
      if (data.presale_display_begin == '' || data.presale_display_begin == undefined) {
        data.presale_display_begin = 0
      }
      if (data.presale_display_end == '' || data.presale_display_end == undefined) {
        data.presale_display_end = 0
      }
      if (data.presale_begin == '' || data.presale_begin == undefined) {
        data.presale_begin = 0
      }
      if (data.presale_end == '' || data.presale_end == undefined) {
        data.presale_end = 0
      }
      console.log(data.presale_display_begin)
      data.presale_display_begin = this.dateToMs(data.presale_display_begin) / 1000
      data.presale_display_end = this.dateToMs(data.presale_display_end) / 1000
      data.presale_begin = this.dateToMs(data.presale_begin) / 1000
      data.presale_end = this.dateToMs(data.presale_end) / 1000
      if (type == 'add') {
        // 酒店、民宿基本信息
        const DATA = {
          name: data.name,
          describe: data.describe,
          landlord_mobile: data.landlord_mobile,
          supplier_id: data.supplier_id,
          level: data.level,
          buy_type: data.buy_type,
          vr_model: data.vr_model,
          hotel_pic: data.hotel_pic,
          images: data.images,
          hotel_tags: data.hotel_tags,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          lng_lat: data.lng_lat,
          license_img: data.license_img,
          landlord_wx: data.landlord_wx,
          landlord_tel: data.landlord_tel,
          landlord_name: data.landlord_name,
          mode: data.mode,
          is_hot: data.is_hot == true ? 1 : 0,
          is_red: data.is_red == true ? 1 : 0,
          // 预订
          book_ahead_minutes: data.book_ahead_minutes,
          min_buy_num: data.min_buy_num,
          max_buy_num: data.max_buy_num,
          min_buy_night: data.min_buy_night,
          max_buy_night: data.max_buy_night,
          is_presale: data.is_presale,
          presale_display_begin: data.presale_display_begin,
          presale_display_end: data.presale_display_end,
          presale_begin: data.presale_begin,
          presale_end: data.presale_end,
          presale_use_begin: data.presale_use_begin,
          presale_use_end: data.presale_use_end,
          presale_description: data.presale_description,
          // 预订规则列表
          book_limit: [
            { type: data.type },
            { cn_name: data.cn_name },
            { en_name: data.en_name },
            { mobile: data.mobile },
            { email: data.email },
            { people_num: data.people_num },
            { credential: data.credential },
            { credential_type: data.credential_type }
          ],
          //	限购规则
          buy_limit: [
            { type: data.buy_limit_type },
            { in_day: data.in_day },
            { in_count: data.in_count }
          ]

        }
        console.log(DATA)
        result = await this.getHotelProductEdit('POST', DATA)
        data = result.data
      } else if (type == 'edit') {
        this.imgIndex.forEach(item => {
          data.images.splice(this.imgIndex, 1)
        })
        const DATA = {
          id: data.id,
          name: data.name,
          describe: data.describe,
          landlord_mobile: data.landlord_mobile,
          supplier_id: data.supplier_id,
          level: data.level,
          buy_type: data.buy_type,
          vr_model: data.vr_model,
          hotel_pic: data.hotel_pic,
          images: data.images,
          hotel_tags: data.hotel_tags,
          province_id: data.province_id,
          city_id: data.city_id,
          county_id: data.county_id,
          town_id: 0,
          address: data.address,
          lng_lat: data.lng_lat,
          license_img: data.license_img,
          landlord_wx: data.landlord_wx,
          landlord_tel: data.landlord_tel,
          landlord_name: data.landlord_name,
          mode: data.mode,
          status: data.status,
          is_hot: data.is_hot == true ? 1 : 0,
          is_red: data.is_red == true ? 1 : 0,

          // 预订
          book_ahead_minutes: data.book_ahead_minutes,
          min_buy_num: data.min_buy_num,
          max_buy_num: data.max_buy_num,
          min_buy_night: data.min_buy_night,
          max_buy_night: data.max_buy_night,
          is_presale: data.is_presale,
          presale_display_begin: data.presale_display_begin,
          presale_display_end: data.presale_display_end,
          presale_begin: data.presale_begin,
          presale_end: data.presale_end,
          presale_use_begin: data.presale_use_begin,
          presale_use_end: data.presale_use_end,
          presale_description: data.presale_description,
          // 预订规则列表
          book_limit: [
            { type: data.type },
            { cn_name: data.cn_name },
            { en_name: data.en_name },
            { mobile: data.mobile },
            { email: data.email },
            { people_num: data.people_num },
            { credential: data.credential },
            { credential_type: data.credential_type }
          ],
          //	限购规则
          buy_limit: [
            { type: data.buy_limit_type },
            { in_day: data.in_day },
            { in_count: data.in_count }
          ]
        }
        console.log(DATA)
        result = await this.getHotelProductEdit('PUT', DATA)
      }
      return result
    },

    // 酒店政策 数据
    async savaPolicyInfo (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // entry_departure_time		string	入离时间
      // unsubscribe		string	退订政策
      // deposit		string	押金政策
      // pets		string	宠物政策
      // add_beds		string	加床政策
      // meal_explanation		string	用餐说明
      // facility		arr	设施/服务
      const DATA_PolicyInfo = {
        id: data.id,
        entry_departure_time: data.entry_departure_time,
        deposit: data.deposit,
        unsubscribe: data.unsubscribe,
        pets: data.pets,
        add_beds: data.add_beds,
        meal_explanation: data.meal_explanation,
        facility: data.facility.length == 0 ? '' : data.facility
      }
      const res = await this.setHotelProductoPlicyInfo(DATA_PolicyInfo)
      return res
    },
    // 民宿/酒店介绍 数据
    async savaDescriptionInfo (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // description	是	html	图文介绍
      // scenic_spots	是	arr	关联元素库ID
      const DATA_Description = {
        id: data.id,
        description: data.description,
        scenic_spots: data.scenic_spots // 待完善
      }
      const res = await this.setHotelProductdescription(DATA_Description)
      return res
    },
    // 订购须知 数据
    async saveBuyNoticeInfo (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // buy_notice	是	string	订购须知
      const DATA_BuyNotice = {
        id: data.id,
        buy_notice: data.buy_notice
      }
      const res = await this.setHotelProductbuyNotice(DATA_BuyNotice)
      return res
    },
    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = 'first'
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      this.dialogFonfigAudit.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName
    },

    // 表格开关操作
    async changeSwitch (row) {
      // id		酒店、名宿ID
      // status	int	状态 1上架 3下架
      const DATA = {
        id: row.id,
        status: row.status == 0 ? 3 : 1
      }
      console.log(row)
      await this.changeHotelProductStatue(DATA)
    },

    // 获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
    },
    // 编辑操作
    async clickEditData (row) {
      console.log(row);
      // id	是	酒店民宿的Id
      const data = {
        id: row.id
      }

      const result = await this.getHotelProductShow(data)
      const data2 = result.data.data
      //获取二维码
      const resQrcode = await createBindQrcode(data)
      this.TabsFormLabel[0].children[21].images = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + resQrcode.data.data.ticket
      // console.log(this.TabsFormLabel[0].children[23]);
      let wechat_notification_user = data2.wechat_notification_user
      let worker = []
      for (let i = 0; i < wechat_notification_user.length; i++) {
        let data = {
          id: wechat_notification_user[i].id,
          nickname: wechat_notification_user[i].nickname,
          unbind: '解绑'
        }
        worker.push(data)
      }
      this.TabsFormLabel[0].children[23].value = worker
      // console.log(this.TabsFormLabel[0].children[23]);
      this.imgIndex = []
      this.imgIndex.forEach(item => {
        data2.images.splice(this.imgIndex, 1)
      })
      // 处理时间
      if (data2.presale_display_begin == 0) {
        data2.presale_display_begin = ''
      } else {
        data2.presale_display_begin = data2.presale_display_begin * 1000
      }
      if (data2.presale_display_end == 0) {
        data2.presale_display_end = ''
      } else {
        data2.presale_display_end = data2.presale_display_end * 1000
      }
      if (data2.presale_begin == 0) {
        data2.presale_begin = ''
      } else {
        data2.presale_begin = data2.presale_begin * 1000
      }
      if (data2.presale_end == 0) {
        data2.presale_end = ''
      } else {
        data2.presale_end = data2.presale_end * 1000
      }

      console.log(data2)
      if (data2.book_limit == null) {
        this.openForm = {
          id: data2.id,
          name: data2.name,
          describe: data2.describe,
          landlord_mobile: data2.landlord_mobile,
          supplier_id: data2.supplier_id,
          level: data2.level,
          buy_type: data2.buy_type,
          mode: data2.mode,
          vr_model: data2.vr_model,
          hotel_pic: data2.hotel_pic,
          images: data2.images,
          hotel_tags: data2.hotel_tags,
          province_id: data2.province_id,
          city_id: data2.city_id,
          county_id: data2.county_id,
          town_id: 0,
          area: [data2.province_id,
          data2.city_id,
          data2.county_id
            // DATA.data.town_id
          ],
          address: data2.address,
          lng_lat: data2.lng_lat,
          lngAndlat: data2.lng_lat.join(' / '),
          license_img: data2.license_img,
          landlord_wx: data2.landlord_wx,
          landlord_tel: data2.landlord_tel,
          landlord_name: data2.landlord_name,
          status: data2.status,
          is_hot: data2.is_hot == true ? 1 : 0,
          is_red: data2.is_red == true ? 1 : 0,

          // 预订
          book_ahead_minutes: data2.book_ahead_minutes,
          min_buy_num: data2.min_buy_num,
          max_buy_num: data2.max_buy_num,
          min_buy_night: data2.min_buy_night,
          max_buy_night: data2.max_buy_night,
          is_presale: data2.is_presale,
          presale_display_begin: data2.presale_display_begin,
          presale_display_end: data2.presale_display_end,
          presale_begin: data2.presale_begin,
          presale_end: data2.presale_end,
          presale_use_begin: data2.presale_use_begin,
          presale_use_end: data2.presale_use_end,
          presale_description: data2.presale_description,
          // 预购规则
          type: '',
          cn_name: '',
          en_name: '',
          mobile: '',
          email: '',
          people_num: '',
          credential: '',
          credential_type: '',

          // 限购规则
          buy_limit_type: '',
          in_day: '',
          in_count: '',

          // 酒店民宿介绍  富文本数据
          description: data2.description ? data2.description : ' ',
          scenic_spots: data2.scenic_spots ? [1, 2] : [1, 2],
          // 订购须知
          buy_notice: data2.buy_notice ? data2.buy_notice : ' ',
          // 酒店政策
          entry_departure_time: data2.entry_departure_time ? data2.entry_departure_time : ' ',
          unsubscribe: data2.unsubscribe ? data2.unsubscribe : ' ',
          deposit: data2.deposit ? data2.deposit : ' ',
          pets: data2.pets ? data2.pets : ' ',
          add_beds: data2.add_beds ? data2.add_beds : ' ',
          meal_explanation: data2.meal_explanation ? data2.meal_explanation : ' ',
          facility: data2.facility ? data2.facility : []
        }
      } else {
        this.openForm = {
          id: data2.id,
          name: data2.name,
          describe: data2.describe,
          landlord_mobile: data2.landlord_mobile,
          supplier_id: data2.supplier_id,
          level: data2.level,
          buy_type: data2.buy_type,
          mode: data2.mode,
          vr_model: data2.vr_model,
          hotel_pic: data2.hotel_pic,
          images: data2.images,
          hotel_tags: data2.hotel_tags,
          province_id: data2.province_id,
          city_id: data2.city_id,
          county_id: data2.county_id,
          town_id: 0,
          area: [data2.province_id,
          data2.city_id,
          data2.county_id
            // DATA.data.town_id
          ],
          address: data2.address,
          lng_lat: data2.lng_lat,
          lngAndlat: data2.lng_lat.join(' / '),
          license_img: data2.license_img,
          landlord_wx: data2.landlord_wx,
          landlord_tel: data2.landlord_tel,
          landlord_name: data2.landlord_name,
          status: data2.status,
          is_hot: data2.is_hot == true ? 1 : 0,
          is_red: data2.is_red == true ? 1 : 0,

          // 预订
          book_ahead_minutes: data2.book_ahead_minutes,
          min_buy_num: data2.min_buy_num,
          max_buy_num: data2.max_buy_num,
          min_buy_night: data2.min_buy_night,
          max_buy_night: data2.max_buy_night,
          is_presale: data2.is_presale,
          presale_display_begin: data2.presale_display_begin,
          presale_display_end: data2.presale_display_end,
          presale_begin: data2.presale_begin,
          presale_end: data2.presale_end,
          presale_use_begin: data2.presale_use_begin,
          presale_use_end: data2.presale_use_end,
          presale_description: data2.presale_description,

          // 预定规则
          type: Number(data2.book_limit[0].type),
          cn_name: data2.book_limit[1].cn_name == 'true',
          en_name: data2.book_limit[2].en_name == 'true',
          mobile: data2.book_limit[3].mobile == 'true',
          email: data2.book_limit[4].email == 'true',
          people_num: Number(data2.book_limit[5].people_num),
          credential: data2.book_limit[6].credential == 'true',
          credential_type: Number(data2.book_limit[7].credential_type),

          // 限购规则
          buy_limit_type: Number(data2.buy_limit[0].type) == '0' ? 1 : 2,
          in_day: Number(data2.buy_limit[1].in_day),
          in_count: Number(data2.buy_limit[2].in_count),

          // 酒店民宿介绍  富文本数据
          description: data2.description ? data2.description : ' ',
          scenic_spots: data2.scenic_spots ? [1, 2] : [1, 2],
          // 订购须知
          buy_notice: data2.buy_notice ? data2.buy_notice : ' ',
          // 酒店政策
          entry_departure_time: data2.entry_departure_time ? data2.entry_departure_time : ' ',
          unsubscribe: data2.unsubscribe ? data2.unsubscribe : ' ',
          deposit: data2.deposit ? data2.deposit : ' ',
          pets: data2.pets ? data2.pets : ' ',
          add_beds: data2.add_beds ? data2.add_beds : ' ',
          meal_explanation: data2.meal_explanation ? data2.meal_explanation : ' ',
          facility: data2.facility ? data2.facility : []
        }
      }

      console.log(this.openForm)
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 详情 酒店/民宿
    getHotelProductShow (data) {
      return new Promise(resolve => {
        getHotelProductShow(data).then((res) => {
          resolve(res)
        })
      })
    },

    // 审核操作
    clickAuditData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      // reason	是	string	审核理由
      this.auditForm = {
        id: row.id,
        name: row.name,
        image: row.hotel_pic,
        status: row.status
      }
      this.dialogFonfigAudit.isShowDialogVisible = true
    },
    // 审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      const DATA = {
        id: data.id,
        status: statue,
        reason: data.reason
      }
      await this.AuditHotelProduct(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData()
        }
      })// 调用审核接口

      this.dialogFonfigAudit.isShowDialogVisible = false
    },

    // 删除操作
    clickDeleteData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      this.$confirm('确定移除这条记录吗？', '提示', { type: 'error' }).then(() => {
        const DATA = {
          id: row.id
        }
        deleteHotelProduct('DELETE', DATA).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message.success('删除成功！')
          }
          this.updataTableData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 编辑房型
    clickInfoData (row) {
      this.$router.push({ name: 'HotelHomeStay_HomeType', query: { hotel_id: row.id } })
      // let routeData = this.$router.resolve({
      //   name: "HotelHomeStay_HomeType",
      //   query: { hotel_id: row.id },
      // });
      // window.open(routeData.href);
    },
    // 添加服务设备
    clickToAddEquip (data) {
      const name = this.openForm.facility.find((value, index, arr) => {
        return arr[index].name == data
      })

      if (name) {
        this.$message(data + '已添加！！！')
        return
      }
      const DATA = {
        name: data,
        icon: 'icon',
        data: [

        ]
      }

      this.openForm.facility.push(DATA)
    },
    // 删除设备
    clickDeleteEquip (row) {
      const name = this.openForm.facility.find((value, index, arr) => {
        return arr[index].name == row.name
      })
      this.openForm.facility.splice(name.index, 1)
    },
    // 查询操作
    searchHotelProductToAudit (searchForm) {
      console.log(searchForm, "searchForm");
      const data = {
        search: searchForm.hotelName,
        status: searchForm.hotelStatus,
        mode: searchForm.mode,
        supplier_id: searchForm.supplier_id,
        buy_type: searchForm.buy_type,
        province_id: searchForm.province_id,
        city_id: searchForm.city_id,
        status: null,
        is_need_review: null,
        last_date: [searchForm.last_date[0] / 1000, searchForm.last_date[1] / 1000],
        order_by: ['last_date ' + searchForm.order_by],
      }

      this.searchData = data
      console.log(data, "==data")
      if (this.activeTableName == 'first') {
        data.page = this.tableFormLabel[0].tableConfig.page
        data.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
        data.status = 0
        this.getHotelProductToAudit(data)
      } else if (this.activeTableName == 'second') {
        data.page = this.tableFormLabel[1].tableConfig.page
        data.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
        data.status = 3,
          data.is_need_review = 0
        this.getHotelProductToOperation(data)
        // this.getHotelProductToOperation(data)
      }
      else if (this.activeTableName == 'third') {
        data.page = this.tableFormLabel[2].tableConfig.page
        data.page_rows = this.tableFormLabel[2].tableConfig.NumArticle
        data.status = 1,
          this.getHotelProductToOperation(data)
      }
      else if (this.activeTableName == 'fouth') {
        data.page = this.tableFormLabel[3].tableConfig.page
        data.page_rows = this.tableFormLabel[3].tableConfig.NumArticle
        data.status = 2,
          this.getHotelProductToAudit(data)
      }
      else if (this.activeTableName == 'five') {
        this.$set(data, 'is_need_review', 1)

        data.page = this.tableFormLabel[4].tableConfig.page
        data.page_rows = this.tableFormLabel[4].tableConfig.NumArticle
        console.log(data, "===================");
        data.status = 3,
          data.is_need_review = 1
        this.getHotelProductToOperation(data)
        console.log("点击搜索5");
      }
      console.log(searchForm)
    },
    // 重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.hotelName = ''
      searchForm.hotelStatus = ''
      searchForm.mode = ''
      searchForm.supplier_id = ''
      searchForm.buy_type = ''
      searchForm.province_id = ''
      searchForm.city_id = ''
      searchForm.last_date = ''
      searchForm.order_by = ''
      this.searchData = ''
      if (this.activeTableName == 'first') {
        this.getHotelProductToAudit()
      } else if (this.activeTableName == 'second') {
        this.getHotelProductToOperation()
      }
    },
    // 点击放大图片
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogImgVisible = true
    },
    // 选择经纬度 按钮点击事件
    clickAddIngLat () {
      console.log("点击获取经纬度");
      this.inDialog.isShowDialogVisible = true
    },
    // 获得 经纬度
    submitAddress (center, address) {
      this.openForm.lng_lat = center
      this.openForm.lngAndlat = this.openForm.lng_lat.join(' / ')
      this.inDialog.isShowDialogVisible = false
      console.log(address)
      this.openForm.address = address
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  .manage-content {
    /deep/ .el-tabs__content {
      height: 806px;
    }
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
}

//
</style>
